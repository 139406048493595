import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import hero from '../../images/issues/small-business/small-business-hero.webp'
import accomplishment1A from '../../images/issues/small-business/small-business-accomplishment-1a.webp'
import accomplishment1B from '../../images/issues/small-business/small-business-accomplishment-1b.webp'
import accomplishment1C from '../../images/issues/small-business/small-business-accomplishment-1c.webp'
import accomplishment2A from '../../images/issues/small-business/small-business-accomplishment-2a.webp'
import accomplishment2B from '../../images/issues/small-business/small-business-accomplishment-2b.webp'
import accomplishment2C from '../../images/issues/small-business/small-business-accomplishment-2c.webp'
import accomplishment2D from '../../images/issues/small-business/small-business-accomplishment-2d.webp'
import accomplishment2E from '../../images/issues/small-business/small-business-accomplishment-2e.webp'
import accomplishment2F from '../../images/issues/small-business/small-business-accomplishment-2f.webp'
import accomplishment2G from '../../images/issues/small-business/small-business-accomplishment-2g.webp'
import accomplishment3A from '../../images/issues/small-business/small-business-accomplishment-3a.webp'
import accomplishment3B from '../../images/issues/small-business/small-business-accomplishment-3b.webp'
import accomplishment3C from '../../images/issues/small-business/small-business-accomplishment-3c.webp'
import accomplishment3D from '../../images/issues/small-business/small-business-accomplishment-3d.webp'
import accomplishment3E from '../../images/issues/small-business/small-business-accomplishment-3e.webp'
import accomplishment4 from '../../images/issues/small-business/small-business-accomplishment-4.webp'

const IssuesSmallBusinessPage = () => (
  <Layout>
    <Seo title="Issues - Small Business" />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 xl:px-0">
        <div>
          <img
              className="w-full max-h-96 object-cover object-pos-0-40" // TODO: figure out aspect ratio -- trying to set 2:1
              src={hero}
              alt=""
            />
        </div>
        <div className="max-w-5xl mx-auto">
          <h1 className="pt-10 font-bold text-6xl">Small Business</h1>
          <p className="pt-5 text-2xl leading-relaxed">
            Developing a diverse economic base and a skilled workforce will make it easier for us to access quality jobs, goods, and services. We need to facilitate successful businesses, improve pathways to more jobs, higher wages, and facilitate the connection between residents and employers.
          </p>
          <p className="pt-5 text-2xl leading-relaxed">
            Providence thrives on the success of small businesses. They employ many residents, create economic opportunities, and serve as cultural hubs for diverse communities. Councilman Goncalves understands how important protecting rights for entrepreneurs is. He seeks to break the walls that prevent locally owned companies from exceeding and building wealth. This includes developing and supporting high-quality local businesses, promoting our city as a business hub, strengthening education and worker skills and beautifying commercial corridors. Together, we can power an economic revival in Providence focused on access to small businesses and the communities that rely on them.
          </p>
        </div>
        <div className="max-w-5xl mx-auto pt-10">
          <span className="text-base tracking-widest uppercase">John's Record</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl pb-10">What We've Accomplished Together</h2>
          {/*TODO: L side - bullet point, R side - image - TEXT John when I'm working on this, Sanctuary, John visiting Boston - Mass & Cass, etc.*/}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <div>
              <h3 className="font-bold text-xl">Supported Local Businesses &amp; Innovation</h3>
              <p>• Identified and actively shared available resources with Ives Street, Wickenden Street, Wayland Square, South Main/South Water, and Downtown businesses to improve their access and knowledge of City and State grant opportunities.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment1C}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment1B}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment1A}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Promoted Outdoor Dining To Held Keep Businesses Afloat During The Pandemic</h3>
              <p>• Worked closely with the Wayland Merchants Association and Fox Point businesses to facilitate outdoor dining, events, and road closures to spur economic activity during the pandemic. Sponsored annual events and co-created events such as Autumn Air In The Square, Ward 1 Block Party etc. and promoted events like Providence Flea on South Water, Seafood Fest, etc.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2A}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2B}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2C}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2D}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2E}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2F}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2G}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Beautified Our Commercial Corridors</h3>
              <p>• Replaced trash bins, added benches/flower pots, improved lighting, and worked with businesses to create creative placemaking signage to promote shopping and support local businesses.</p>
            </div>
            <div>
              <a href="https://docs.google.com/presentation/d/117MUZvLtBflcpjBRUbN0yYZf1O_-VH0G1wWbokiDFeE/edit#slide=id.g35f391192_00" target="_blank">
                <img
                  className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                  src={accomplishment3A}
                  alt=""
                />
              </a>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3B}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3C}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3D}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3E}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Advocated For Fast-Growing Sectors</h3>
              <p>• Remained a proponent of "knowledge-based" jobs in information technology, engineering, biomedical, biotechnology, and other fast-growing sectors in the Jewelry District and urged the I-195 District Commission to remain true to its mission of economic development in Ward 1 and in the City of Providence. Read more <a href="https://www.wpri.com/news/local-news/providence/neighbors-vexed-by-riverfront-apartments-proposed-on-old-195-land/" target="_blank">here</a> and <a href="https://www.wpri.com/news/local-news/providence/10-years-later-old-195-land-is-getting-developed-but-land-sales-fall-far-short/" target="_blank">here</a>.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment4}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="max-w-5xl mx-auto pt-10 pb-10">
          <span className="text-base tracking-widest uppercase">Our Policy Platform Moving Forward</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl">The Work We Still Have To Do</h2>

          <p className="text-xl mt-5">Providence thrives on the success of small businesses. They employ many residents, create economic opportunities, and serve as cultural hubs for diverse communities. John understands how important protecting rights for entrepreneurs is. He seeks to break the walls that prevent locally owned companies from exceeding and building wealth. This includes improving permit and licenses application and acceptance, changing how the city handles contracts and procurement, and supporting small business cooperatives focused on worker rights and equality. Together, we can power an economic revival in Providence focused on access to small businesses and the communities that rely on them.</p>

          <h3 className="font-bold text-3xl mt-5">Supporting Local Businesses Now and in the Future</h3>
          <p className="text-xl">The COVID-19 pandemic has strained local business development and growth considerably. These challenges create a lack of stability, increased unemployment, and financial woes. We must provide access in a fair and equitable manner to small business owners who need extra services and assistance at this time.</p>

          <h3 className="font-bold text-3xl mt-5">Redirect City Contracts Back Into the Community</h3>
          <p className="text-xl">Efforts to improve taxpayer dollar use require redirecting them back into the community and small businesses that help support all people. This is especially true for companies owned and operated by women, people of color, and immigrants who form the backbone of a large part of Providence's economy. Leveling the playing field for city contracts is just one part of an equitable restructuring.</p>

          <h3 className="font-bold text-3xl mt-5">Improve the Small Business Permit and License Process</h3>
          <p className="text-xl">In an effort to support entrepreneurs from all communities, the city of Providence must adopt a convenient, efficient, and accessible method for acquiring small business licenses and permits. This includes clear process information, multilingual communication, and full transparency for timelines and requirements.</p>

          <h3 className="font-bold text-3xl mt-5">Support Legacy Businesses and Main Street Anchors</h3>
          <p className="text-xl">The smaller businesses found on main streets and in neighborhoods throughout the city provide financial and community anchors for all. The past year has seen too many legacy or long-standing businesses close their doors due to economic difficulties. This affects the culture and financial strength of these neighborhoods. These challenging times need a strong COVID-19 recovery plan that supports established companies.</p>

          <h3 className="font-bold text-3xl mt-5">Inclusion for POC Entrepreneurs</h3>
          <p className="text-xl">Historical and systemic racial wage disparity and inaccessibility to business owners have left many people of color behind. We must fight for more inclusion for all entrepreneurs regardless of their heritage.</p>

          <h3 className="font-bold text-3xl mt-5">Offer Unique Support for Restaurants</h3>
          <p className="text-xl">Recent shutdowns associated with the pandemic have affected restaurants and other food-related businesses greatly. In conjunction with resource allotment from the federal, state, and local governments, Providence must create a unique plan for rebuilding and reinvigorating the restaurant scene. New ideas should include everything from operational existence and publicity.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default IssuesSmallBusinessPage
